import React, { useContext } from 'react'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import { BarLoader } from 'react-spinners'
import { useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { StoreContext } from '../contexts/ShopifyStoreContext'
import { GlobalDispatchContext } from '../contexts/GlobalContextProvider'

const Button = styled.button`
  ${tw`flex items-center justify-center h-16 w-full px-5 mb-4 bg-colekt-black text-almost-white uppercase font-extended tracking-widest text-center hover:opacity-90`}
  font-size: 13px;

  &:disabled {
    ${tw`opacity-75`}
  }
`

export function AddToCart({ product, variant, variantId, quantity, available, ...props }) {
  const dispatch = useContext(GlobalDispatchContext)

  const { addVariantToCart, loading } = React.useContext(StoreContext)

  const { inventoryQuantity } = variant

  const inStock = inventoryQuantity > 0

  function addToCart(e) {
    e.preventDefault()
    addVariantToCart(variantId, quantity)

    setTimeout(() => {
      dispatch({ type: 'TOGGLE_CART' })
    }, 1000)

    window.dataLayer = window.dataLayer || []

    window.dataLayer.push({
      event: 'add_to_cart',
      ecommerce: {
        items: [
          {
            item_name: product?.title,
            item_id: variant?.sku,
            price: variant?.price,
            item_brand: 'Colekt',
            item_category: '',
            item_variant: variant?.title,
            quantity,
          },
        ],
      },
    })
  }

  const data = useStaticQuery(graphql`
    query {
      wp {
        globalSettings {
          localizedStrings {
            addToBag
            outOfStock
          }
        }
      }
    }
  `)

  const { addToBag, outOfStock } = data.wp.globalSettings.localizedStrings

  const buttonLabel = inStock ? addToBag || 'Add to Bag' : 'Pre-order'

  return (
    <Button type="submit" aria-label={addToBag} onClick={addToCart} disabled={!available || loading} {...props}>
      {loading ? (
        <BarLoader width={150} color="#ffffff" loading />
      ) : (
        <>{available ? buttonLabel : outOfStock || 'Out of Stock'}</>
      )}
    </Button>
  )
}

AddToCart.propTypes = {
  product: PropTypes.object.isRequired,
  variant: PropTypes.object.isRequired,
  variantId: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  available: PropTypes.bool,
  inventoryQuantity: PropTypes.number,
}
