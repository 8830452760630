import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import tw from 'twin.macro'
// eslint-disable-next-line import/no-unresolved
import sanitizeHtml from 'sanitize-html'

const Content = styled.div`
  ${tw`font-extended text-center text-xl mb-5`}
`

const Author = styled.div`
  ${tw`font-extended text-center md:text-base mb-12 text-base`}
`

const ProductReview = ({ author, content }) => (
  <>
    <Content
      dangerouslySetInnerHTML={{
        __html: sanitizeHtml(content),
      }}
    />
    <Author>{author}</Author>
  </>
)

ProductReview.propTypes = {
  content: PropTypes.string.isRequired,
  author: PropTypes.string,
}

export default ProductReview
