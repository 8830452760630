import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import { getImage } from 'gatsby-plugin-image'
import { BgImage } from 'gbimage-bridge'
import { Media } from 'gatsby-plugin-fresnel'

const Image = styled.div`
  ${tw`relative`}
`

const ProductImage = ({ image }) => {
  const productImage = getImage(image?.localFile.childImageSharp)

  return (
    <Image>
      <Media lessThan="lg">
        <BgImage image={productImage} style={{ minHeight: '400px', height: '550px' }} />
      </Media>
      <Media greaterThanOrEqual="lg">
        <BgImage image={productImage} style={{ minHeight: '400px', height: '100vh' }} />
      </Media>
    </Image>
  )
}

ProductImage.propTypes = {
  image: PropTypes.object,
}

export default ProductImage
