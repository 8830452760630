import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import { useStaticQuery, graphql } from 'gatsby'
import sanitizeHtml from 'sanitize-html'
import IconShipping from '../../assets/truck.svg'
import IconSamples from '../../assets/vial.svg'

const Wrapper = styled.ul`
  ${tw`border-b pb-4`}
  font-size: 12px;
`

const Row = styled.li`
  ${tw`flex items-center mb-2`}
`

const Icon = styled.div`
  ${tw`mr-2`}
  margin-top: 2px;
  svg {
    width: 12px;
  }
`

const Content = styled.div`
  ${tw``}
`

const Button = styled.button`
  ${tw`underline`}
`

const ShippingInformation = styled.li`
  ${tw`bg-white p-4 mt-1 mb-2 font-mono`}

  a {
    ${tw`underline`}
  }
`

const ProductBuyingInformation = ({ available, inventoryQuantity, shippingTitleAlt, shippingBodyAlt }) => {
  const data = useStaticQuery(graphql`
    query {
      wp {
        globalSettings {
          productPage {
            shippingBody
            shippingTitle
            samplesTitle
          }
          localizedStrings {
            hideDetails
            showDetails
            outOfStock
          }
        }
      }
    }
  `)

  const { globalSettings } = data.wp

  const { localizedStrings, productPage } = globalSettings

  const { shippingBody, shippingTitle, samplesTitle } = productPage

  const { hideDetails, showDetails, outOfStock } = localizedStrings

  const [shippingInformationIsOpen, setShippingInformationIsOpen] = useState(false)

  const handleClick = () => {
    setShippingInformationIsOpen(!shippingInformationIsOpen)
  }

  const inStock = inventoryQuantity > 0

  return (
    <Wrapper>
      <Row>
        <Icon>
          <IconShipping />
        </Icon>
        <Content>
          {available ? (
            <>
              {inStock ? shippingTitle || 'Shipping information' : shippingTitleAlt || 'Shipping information'} –{' '}
              <Button type="button" onClick={handleClick}>
                {shippingInformationIsOpen ? hideDetails || 'Hide details' : showDetails || 'Show details'}
              </Button>
            </>
          ) : (
            outOfStock || 'Out of stock'
          )}
        </Content>
      </Row>
      {shippingInformationIsOpen ? (
        <ShippingInformation
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(inStock ? shippingBody : shippingBodyAlt || shippingBody),
          }}
        />
      ) : null}
      {samplesTitle && (
        <Row>
          <Icon>
            <IconSamples />
          </Icon>
          <Content>{samplesTitle}</Content>
        </Row>
      )}
    </Wrapper>
  )
}

ProductBuyingInformation.propTypes = {
  available: PropTypes.bool,
  inventoryQuantity: PropTypes.number,
  shippingTitleAlt: PropTypes.string,
  shippingBodyAlt: PropTypes.string,
}

export default ProductBuyingInformation
