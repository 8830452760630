/* eslint-disable import/no-unresolved */
import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import tw from 'twin.macro'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination } from 'swiper/core'
import ProductImage from './ProductImage'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
// install Swiper modules
SwiperCore.use([Pagination])

const ProductImageCarousel = (props) => {
  const { images } = props

  const ImageGallery = styled.div`
    ${tw`w-full`}
  `

  return (
    <div>
      <ImageGallery>
        <Swiper pagination height="100%" autoHeight>
          {images?.map((image, index) => (
            <SwiperSlide index={index} key={`product-image-${image.id}`}>
              <ProductImage image={image.image} />
            </SwiperSlide>
          ))}
        </Swiper>
      </ImageGallery>
    </div>
  )
}

ProductImageCarousel.propTypes = {
  images: PropTypes.array.isRequired,
}

export default ProductImageCarousel
