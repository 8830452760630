import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import tw from 'twin.macro'
// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination } from 'swiper'
import { useLazyQuery } from '@apollo/client'
import { GET_PRODUCT_REVIEWS } from '../../../queries/get-product-reviews'
import ProductReview from './ProductReview'

// eslint-disable-next-line import/no-unresolved
import 'swiper/css'
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/pagination'

SwiperCore.use([Pagination])

const Wrapper = styled.div`
  ${tw`border-b mb-6 pb-6`}
`

const Title = styled.h3`
  ${tw`text-base uppercase font-extended tracking-wider mb-5`}
  padding-left: 2px;
`

const ProductReviews = ({ sku }) => {
  const swiperRef = useRef(null)

  const [loadReviews, { loading, data }] = useLazyQuery(GET_PRODUCT_REVIEWS)

  useEffect(() => {
    loadReviews({
      variables: {
        slug: sku,
      },
    })
  }, []) // called once

  if (data?.products?.nodes[0]?.reviews.nodes.length > 1) {
    return (
      <Wrapper>
        <Title>What others say</Title>
        {data?.products && (
          <Swiper
            pagination={{
              clickable: true,
            }}
            loop="true"
            spaceBetween={0}
            ref={swiperRef}
            slidesPerView={1}
            height="100%"
            autoHeight
          >
            {data?.products?.nodes[0]?.reviews.nodes.map((review, index) => (
              <SwiperSlide index={index} key={review.id}>
                {() => (
                  <div>
                    <ProductReview content={review.content} author={review.reviews.author} />
                  </div>
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </Wrapper>
    )
  }

  if (data?.products?.nodes[0]?.reviews.nodes.length === 1) {
    return (
      <Wrapper>
        <Title>What others say</Title>
        <ProductReview
          content={data?.products?.nodes[0]?.reviews.nodes[0].content}
          author={data?.products?.nodes[0]?.reviews.nodes[0].reviews.author}
        />
      </Wrapper>
    )
  }
  return null
}

ProductReviews.propTypes = {
  sku: PropTypes.string.isRequired,
}

export default ProductReviews
