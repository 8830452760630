import { gql } from '@apollo/client'

export const GET_PRODUCT_REVIEWS = gql`
  query ProductReviews($slug: [String] = null) {
    products(where: { slug: $slug }) {
      nodes {
        id
        reviews {
          nodes {
            content
            id
            reviews {
              author
            }
          }
        }
      }
    }
  }
`
